// SPACING
//margin bottom
.mb-0 {
    margin-bottom: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-70 {
    margin-bottom: 70px;
}

//margin top
.mt-0 {
    margin-top: 0;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

//margin top and bottom
.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

//margin left
.ml-10{
    margin-left: 10px;
}

.ml-20{
    margin-left:20px;
}

//margin right
.mr-10{
    margin-right:10px;
}

.mr-20{
    margin-right:20px;
}

//margin all sides
.m-0 {
    margin: 0;
}

//padding bottom
.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-70 {
    padding-bottom: 70px;
}

//padding top
.pt-20 {
    padding-top: 20px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-70 {
    padding-top: 70px;
}

//padding top and bottom
.py-10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

//padding left
.pl-5{
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

//padding right
.pr-5{
    padding-right:5px;
}
.pr-10 {
    padding-right: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

//padding left and right
.px-10 {
    padding-right: 10px;
    padding-left: 10px;
}

.px-20 {
    padding-right: 20px;
    padding-left: 20px;
}

.px-30 {
    padding-right: 30px;
    padding-left: 30px;
}

.px-40 {
    padding-right: 40px;
    padding-left: 40px;
}