$darkblue: #2d4372;
$lightblue: #5f9bbc;
$lightbluetransparent: #5f9bbcb9;

$darkerlightblue: #3a7495;
$orange: #efa145;
$darkerorange: #d88727;
$green: #97b49f;
$darkergreen: #79907f;
$lightyellow: #fffbdb;
$tan: #dfd2c2;
$tanBg: #dfd2c278;
$white: #fff;
$light-black: #464646;
$mediumblack: #2a2a2a;
$airsuperiorityblue: #6698b7;

// COLOR CONFIG
$nav-btn-hover: $darkblue;
$side-bar-bg: linear-gradient(#192743 65px,#1f3155 85px,#23365d 95px, #283e69 105px, #2d4372 125px);
$nav-icon-color: white;
$nav-label-color: white;
$footer-background-color: lightyellow;

// FONT COLORS
.text-color-darkblue {
  color: $darkblue;
}
.text-color-lightblue {
  color: $lightblue;
}
.text-color-green {
  color: $green;
}
.white-text {
  color: white;
}
.blue-text {
  color: blue;
}
