.top-nav {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $darkblue;
    color: #FFF;
    height: 50px;
    padding: 1em;
}

.menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu>li {
    margin: 0 1rem;
    overflow: hidden;
}

.menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#menu-toggle {
    display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
}

.menu-button::before {
    content: '';
    margin-top: -8px;
}

.menu-button::after {
    content: '';
    margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
}

@media (max-width: $screen-max-md) {
    .menu-button-container {
        display: flex;
    }
    .menu {
        position: absolute;
        top: 0;
        margin-top: 50px;
        left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    #menu-toggle~.menu li {
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked~.menu li {
        height: 2.5em;
        padding: 0;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .menu>li {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 100%;
        color: black;
        background-color: white;
    }
    .menu>li>a {
        font-family: $secondary-font;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 100%;
        color: black;
        background-color: white;
        text-decoration: none;
        &:hover,
        &:active,
        &:focus {
            background-color: $lightbluetransparent;
        }
    }
    .menu>li:not(:last-child) {
        border-bottom: 1px solid #444;
    }
}